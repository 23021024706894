import Head from 'next/head';
import { SliceZone } from '@prismicio/react';
import * as prismic from '@prismicio/client';
import * as prismicH from '@prismicio/helpers';
import { RichText } from 'prismic-reactjs';

import { components } from '../slices';
import { createClient } from '../prismicio';

import { Layout } from '../components/Layout';

import {
  Page,
  PAGE_BACKGROUND_PRISMIC_MAP,
  PAGE_BACKGROUND,
} from '../components/Page';

import { normalizeSlug } from '../helpers/normalize-slug.js';
import {
  findAllDynamicPages,
  isSlugCorrespondingToDynamicPage,
  getDynamicPageKey,
  getDynamicPageDocumentUid,
} from '../helpers/dynamic-page.js';

import { changeImageUrl, isImage } from '../helpers/image.js';
import { getPageData } from '../helpers/get-data';
import { CONFIG, DEFAULT_LOCALE, LOCALES } from '../config/index.js';
import { generateLinkResolver } from '../helpers/link-resolver';
import { useGlobalContext } from '../helpers/context';

import React, { useEffect } from 'react';

const generatePagePath = (page) => {
  const locale = LOCALES.find((item) => {
    return item.locale === page.lang;
  });

  const pathPrefix =
    locale?.shortName !== DEFAULT_LOCALE.shortName &&
    page.data.slug !== locale?.pathPrefix
      ? locale?.pathPrefix
      : '';

  return `${pathPrefix}${page.data.slug}`;
};

const PAGE_THEME = {
  DEFAULT: 'default',
  WHITE: 'white',
};

const GlobalPage = ({
  menu,
  layout,
  nonDynamicCompatibleHref,
  allSharedSlices,
  articles,
  categories,
  locale,
  events,
  page,
  missions,
  document = null,
}) => {
  const { setNonDynamicCompatibleHref } = useGlobalContext();

  useEffect(() => {
    setNonDynamicCompatibleHref(nonDynamicCompatibleHref);
  }, [nonDynamicCompatibleHref]);

  /**
   * Create a new flat body array with flattened shared slices items.
   */
  const body = page.data.body.slice().reduce((acc, slice) => {
    if (slice.slice_type === 'shared_slices') {
      const foundSharedSlices = allSharedSlices.find((item) => {
        return item?.id === slice?.primary?.shared_slices?.id;
      });

      if (foundSharedSlices) {
        acc.push(slice);
        acc.push(...foundSharedSlices.data.slices);
      }
    } else {
      acc.push(slice);
    }

    return acc;
  }, []);

  const isArticle = document && document.type === 'article';
  const category = categories.find((category) => {
    return category.uid === document?.data?.category?.uid;
  });

  const displaySkyline =
    page.data.body[body.length - 1]?.slice_type !== 'booking';

  const theme = page.data.theme || PAGE_THEME.DEFAULT;

  const index = !locale?.current?.index
    ? false
    : (document && document.type === 'article') ||
      (document && document.type === 'mission')
    ? document.data.index
    : page.data.index;

  const title =
    (document && document.type === 'article') ||
    (document && document.type === 'mission')
      ? prismicH.asText(document.data.seoTitle) ||
        prismicH.asText(document.data.title)
      : prismicH.asText(page.data.seoTitle);

  const seoDescriptionField =
    (document && document.type === 'article') ||
    (document && document.type === 'mission')
      ? document.data.seoDescription
      : page.data.seoDescription;

  const description =
    typeof seoDescriptionField === 'string'
      ? seoDescriptionField
      : typeof seoDescriptionField === 'object'
      ? prismicH.asText(seoDescriptionField)
      : '';

  const imageUrl =
    document && document.type === 'article' && isImage(document.data.image)
      ? document.data.image.url
      : page.data.seoImage?.url
      ? page.data.seoImage?.url
      : layout.data.seoImage?.url
      ? layout.data.seoImage?.url
      : null;

  const pageBackground =
    PAGE_BACKGROUND_PRISMIC_MAP[page.data.background] || PAGE_BACKGROUND.AUTO;

  const alwaysOpenFaqSlices = body.filter((slice) => {
    return slice.slice_type === 'faq' && !slice.primary.displayOption;
  });

  const structuredDatas = {
    localBusiness: {
      '@context': 'https://schema.org',
      '@type': ['TouristAttraction'],
      name: 'Batman Escape',
      address: {
        '@type': 'PostalAddress',
        streetAddress: '30 avenue Corentin Cariou',
        addressLocality: 'Paris',
        addressRegion: 'Île-de-France',
        postalCode: '75019',
        addressCountry: 'Fr',
      },
      url: 'https://batman-escape.com/',
    },
  };

  if (isArticle) {
    const isoDatePublished = new Date(document.data.publishDate).toISOString();

    const isoDateModified = new Date(
      document.last_publication_date
    ).toISOString();

    const width = 1200;

    const imageUrl = changeImageUrl(document.data.image.url, width);

    const height =
      (width / document.data.image.dimensions.width) *
      document.data.image.dimensions.height;

    structuredDatas.article = {
      '@context': 'https://schema.org',
      '@type': 'Article',
      headline: prismicH.asText(document.data.title),
      datePublished: isoDatePublished,
      dateModified: isoDateModified,
      image: {
        '@type': 'ImageObject',
        url: imageUrl,
        width,
        height,
      },
    };
  }

  if (alwaysOpenFaqSlices.length > 0) {
    const structuredData = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: [],
    };
    alwaysOpenFaqSlices.forEach((slice) => {
      slice.items.forEach((item) => {
        structuredData.mainEntity.push({
          '@type': 'Question',
          name: RichText.asText(item.title),
          acceptedAnswer: {
            '@type': 'Answer',
            text: RichText.asText(item.content),
          },
        });
      });
    });
    structuredDatas.faq = structuredData;
  }

  let prefetchLink = null;
  const hero = body.find((slice) => {
    return slice.slice_type === 'hero';
  });
  if (hero && isImage(hero?.primary?.background)) {
    prefetchLink = (
      <link
        rel="preload"
        as="image"
        href={changeImageUrl(hero.primary.background.url, 769)}
      />
    );
  }
  const heroTitle = body.find((slice) => {
    return slice.slice_type === 'hero_title';
  });
  if (heroTitle && isImage(hero?.primary?.background)) {
    prefetchLink = (
      <link
        rel="preload"
        as="image"
        href={changeImageUrl(hero.primary.background.url, 769)}
      />
    );
  }

  return (
    <Layout
      menu={menu}
      layout={layout}
      locale={locale}
      theme={theme}
      isArticle={isArticle}
      displaySkyline={displaySkyline}
    >
      <Head>
        <title>{title || CONFIG.title}</title>
        {!index ? <meta name="robots" content="noindex,nofollow" /> : null}
        <link
          rel="canonical"
          href={`https://${process.env.HOST}${normalizeSlug(locale.slug)}`}
        />
        <meta name="description" content={description || CONFIG.description} />
        {prefetchLink}

        <meta
          key="twitter-title"
          name="twitter:title"
          content={title || CONFIG.title}
        />
        <meta
          key="twitter-description"
          name="twitter:description"
          content={description || CONFIG.description}
        />
        <meta key="twitter-image" name="twitter:image" content={imageUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          key="facebook-title"
          prefix="og: http://ogp.me/ns#"
          property="og:title"
          content={title || CONFIG.title}
        />
        <meta key="facebook-type" property="og:type" content="website" />
        <meta
          key="facebook-description"
          prefix="og: http://ogp.me/ns#"
          property="og:description"
          content={description || CONFIG.description}
        />
        <meta
          property="og:url"
          content={`https://${process.env.HOST}${normalizeSlug(locale.slug)}`}
        />
        <meta
          key="facebook-image"
          prefix="og: http://ogp.me/ns#"
          name="image"
          property="og:image"
          content={imageUrl}
        />
        <meta
          key="facebook-image-alt"
          prefix="og: http://ogp.me/ns#"
          property="og:image:alt"
          content={title || CONFIG.description}
        />
      </Head>

      {Object.values(structuredDatas).map((data, index) => {
        return (
          <script
            key={index}
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(data),
            }}
          />
        );
      })}

      <Page background={pageBackground} isArticle={isArticle}>
        <SliceZone
          slices={body}
          components={components}
          context={{
            articles,
            missions,
            events,
            page,
            document,
            layout,
            isArticle,
            categories,
          }}
        />
      </Page>
    </Layout>
  );
};

export async function getStaticProps({ params, previewData }) {
  const client = createClient({ previewData });

  let pathPrefix = '';

  if (
    params.slug &&
    LOCALES.find((item) => item.shortCode === params.slug[0])
  ) {
    pathPrefix = params.slug.shift();
  }

  const requestLang = pathPrefix
    ? LOCALES.find((item) => item.shortCode === pathPrefix)
    : DEFAULT_LOCALE;

  const slug = `/${(params.slug || []).join('/')}`;

  const articles = await client.getAllByType('article', {
    lang: requestLang?.locale,
  });

  const missions = await client.getAllByType('mission', {
    lang: requestLang?.locale,
  });

  const events = await client.getAllByType('event', {
    lang: requestLang?.locale,
  });

  const categories = await client.getAllByType('category', {
    lang: requestLang?.locale,
  });

  const pages = await client.getAllByType('page', {
    lang: requestLang?.locale,
  });

  const rest = {};

  try {
    rest.page = await client.getFirst({
      predicates: [
        prismic.predicate.at('document.type', 'page'),
        prismic.predicate.at('my.page.slug', slug),
      ],
      lang: requestLang?.locale,
    });
  } catch (error) {}

  if (!rest.page) {
    const dynamicPages = findAllDynamicPages(pages);

    //Find category
    const foundCategory = categories.find((category) => {
      const name = category?.data?.name;

      return (
        name && normalizeSlug(name.toLowerCase()) === params.slug.join('/')
      );
    });

    if (foundCategory) {
      const dynamicPage = pages.find(
        (page) => page?.data?.specialType === 'category'
      );
      rest.page = dynamicPage;
      rest.document = foundCategory;
    }

    //Find article
    const foundArticle = articles.find((article) => {
      return (
        article.uid === params.slug.slice(1).join('/') ||
        article.uid === params.slug.join('/')
      );
    });

    if (foundArticle) {
      const dynamicPage = pages.find(
        (page) => page?.data?.specialType === 'article'
      );
      rest.page = dynamicPage;
      rest.document = foundArticle;
    } else {
      for (const dynamicPage of dynamicPages) {
        if (
          isSlugCorrespondingToDynamicPage({
            page: dynamicPage,
            slug,
          })
        ) {
          const key = getDynamicPageKey(dynamicPage);

          const uid = getDynamicPageDocumentUid({
            page: dynamicPage,
            slug,
          });

          let document = null;

          try {
            document = await client.getByUID(key, uid, {
              lang: requestLang?.locale,
            });
          } catch (error) {}

          if (document) {
            rest.page = dynamicPage;
            rest.document = document;
          }
        }
      }
    }
  }

  if (!rest.page) {
    return {
      notFound: true,
    };
  }

  const currentLocale = LOCALES.find((item) => item.locale === rest.page.lang);

  const menu = await client.getSingle('menu', { lang: rest.page.lang });
  const layout = await client.getSingle('layout', { lang: rest.page.lang });

  const allSharedSlices = await client.getAllByType('shared_slices', {
    lang: rest.page.lang,
  });

  const allPages = await client.getAllByType('page', { lang: '*' });
  const nonDynamicCompatibleHref = allPages
    .filter((page) => {
      return !!(
        page?.data?.body &&
        page?.data?.body.find((slice) => {
          return slice.slice_type === 'booking';
        })
      );
    })
    .map(generatePagePath);

  const linkResolver = generateLinkResolver({
    articles,
    categories,
    pages,
  });

  const fullSlug = rest.document
    ? prismicH.asLink(rest.document, linkResolver)
    : rest.page.data.slug;

  return {
    props: {
      menu,
      layout,
      nonDynamicCompatibleHref,
      locale: {
        alternate_languages: await getPageData(
          rest.page.id,
          currentLocale?.locale,
          client,
          rest.document ? rest.document : null
        ),
        current: currentLocale,
        slug: pathPrefix + fullSlug,
      },
      allSharedSlices,
      articles: articles.map((article) => {
        const item = article.data;

        const textSlice = item.slices.find((slice) => {
          return [
            'article_content_top',
            'article_content_bottom',
            'article_single',
          ].includes(slice.slice_type);
        });

        const paraph = textSlice
          ? textSlice.primary.leftContent ||
            textSlice.primary.rightContent ||
            textSlice.primary.content ||
            ''
          : '';

        const description = (prismicH.asText(paraph) || '').slice(0, 128);

        return {
          type: article.type,
          id: article.id,
          uid: article.uid,
          lang: currentLocale?.locale,
          data: {
            useArticleSlug: article.data.useArticleSlug,
            category: article.data.category?.id
              ? {
                  id: article.data.category.id,
                }
              : null,
            image: {
              url: article.data.image.url,
              alt: article.data.image.alt,
            },
            index: article.data.index,
            publishDate: article.data.publishDate,
            title: article.data.title,
            description,
          },
        };
      }),

      missions: missions.map((mission) => {
        return {
          type: mission.type,
          id: mission.id,
          uid: mission.uid,
          lang: currentLocale?.locale,
          data: {
            content: mission.data.content || null,

            index: mission.data.index,
          },
        };
      }),

      events: events.map((event) => {
        return {
          type: event.type,
          id: event.id,
          uid: event.uid,
          lang: currentLocale?.locale,
          data: {
            content: event.data.content || null,
            image: {
              url: event.data.image.url,
              alt: event.data.image.alt,
            },
            date: event.data.date,
            title: event.data.title,
          },
        };
      }),

      categories: categories.map((category) => {
        return {
          type: category.type,
          id: category.id,
          uid: category.uid,
          lang: currentLocale?.locale,
          data: {
            name: category.data.name,
          },
        };
      }),

      ...rest,
    },
  };
}

export async function getStaticPaths() {
  const client = createClient();

  const pages = await client.getAllByType('page', { lang: '*' });
  const articles = await client.getAllByType('article', { lang: '*' });
  const missions = await client.getAllByType('mission', { lang: '*' });
  const categories = await client.getAllByType('category', { lang: '*' });

  const linkResolver = generateLinkResolver({
    articles,
    categories,
    pages,
  });

  const paths = [
    ...pages
      .map((page) => {
        return generatePagePath(page);
      })
      .filter((path) => {
        return path && !path.includes(':') && path !== '/404';
      }),

    ...articles.map((article) => {
      return prismicH.asLink(article, linkResolver);
    }),

    ...missions.map((mission) => {
      return prismicH.asLink(mission, linkResolver);
    }),

    ...categories.map((category) => {
      return prismicH.asLink(category, linkResolver);
    }),
  ];

  return {
    paths,
    fallback: false,
  };
}

export default GlobalPage;
